import React from "react"

import { useIntl } from "react-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ExchangeNavigation from "../components/exchange_navigation"
import ExchangeBuy from "../components/exchange_buy"

const ExchangeBuyPage = ({ location }) => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage({ id: "Buy" })
  return (
    <Layout pageTitle={pageTitle} location={location}>
      <SEO title={pageTitle} />
      <ExchangeNavigation location={location} />
      <ExchangeBuy />
    </Layout>
  )
}

export default ExchangeBuyPage
